import imgGoodduck from '../resources/img/loading-spinner.png';
import ellipse from '../resources/img/ellipse-30.svg';
import styled, { keyframes } from 'styled-components';

type IProps = {
  onlyDuck?: boolean | undefined;
}

const BasicLoading = ({ onlyDuck }: IProps) => {
  return (
    <div className='full-size-fixed-box' style={{ background: '#fff' }}>
      <div>
        <BackDiv>
          <ImgDiv src={imgGoodduck} alt="good duck" />
        </BackDiv>
      </div>
    </div>
  );
};

export default BasicLoading;

const animation1 = keyframes`
      0% { background-image: none; }
      25% { background-image: none; }
      50% { background-image: url(${ellipse}); }
      75% { background-image: none; }
      100% { background-image: none; }
    `;

const animation2 = keyframes`
      0% { width: 30px; height: 30px; }
      25% { width: 30px; height: 30px; }
      50% { width: 20px; height: 21px; }
      75% { width: 30px; height: 30px; }
      100% { width: 30px; height: 30px; }
    `;

const BackDiv = styled.div`
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${animation1} 1s infinite;
  `;

const ImgDiv = styled.img`
    width: 30px;
    animation: ${animation2} 1s infinite;
  `;