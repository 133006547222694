import { Icon } from '@iconify/react';
import logo from '../resources/img/logo.png'

const Error = () => (
  <div className='error-box'>
    <img src={logo} alt="logo" />
    <div style={{ marginTop: '38px' }}>
      <Icon icon="octicon:alert-24" width={38} height={38} color='#FF7E43' />
      <p className='error-text'>
        페이지를 찾을 수 없습니다.
      </p>
      <p className="error-desc">
        페이지의 주소가 변경 혹은 삭제되어<br />
        요청하신 페이지를 찾을 수 없습니다.
      </p>
    </div>
  </div>
)

export default Error;