import axios from "axios";

const defaultHeaders = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
};

export const api__getArtistData = async (shareId) => {
  const response = await axios.get(`${process.env.REACT_APP_API}/api/calendar/share/info?shareId=${shareId}`, {
    headers: { ...defaultHeaders },
  });
  return response.data;
};

export const api__getCalendarData = async (shareId) => {
  const response = await axios.get(`${process.env.REACT_APP_API}/api/calendar/share/list?shareId=${shareId}&page=1&pageSize=100`, {
    headers: { ...defaultHeaders },
  });
  return response.data;
};
