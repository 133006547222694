import React, { useEffect, useState } from 'react';
import './App.css';
import { api__getArtistData, api__getCalendarData } from './apis/shareService';
import { debounce } from 'lodash'
import moment from 'moment';
import basic1 from '../src/resources/img/post-back-1.png';
import calEmpty from '../src/resources/img/calendar-empty.png';
import calTag1 from '../src/resources/img/festival.png';
import calTag2 from '../src/resources/img/music-album.png';
import calTag3 from '../src/resources/img/gift-goods.png';
import calTag4 from '../src/resources/img/culture-contents.png';
import calTag5 from '../src/resources/img/etc-heart.png';
import logo from '../src/resources/img/logo.png';
import { Icon } from '@iconify/react';
import BasicLoading from './components/BasicLoading';
import Error from './components/Error';

type ArtistData = { nameKo: string, coverImgUrl: string };
type CalendarData = {
  title: string;
  tag: string;
  tagName: string;
  scheduleDt: string;
  views: number;
};
const tagType = [
  { code: '001', name: '공연', img: calTag1 },
  { code: '002', name: '앨범', img: calTag2 },
  { code: '003', name: '굿즈', img: calTag3 },
  { code: '004', name: '콘텐츠', img: calTag4 },
  { code: '005', name: '기타', img: calTag5 }
]

function App() {
  const [artistData, setArtistData] = useState<ArtistData | null>(null);
  const [calendarData, setCalendarData] = useState<CalendarData[] | null>(null);
  const [monthList, setMonthList] = useState<any | null>(null);
  const [modal, setModal] = useState(false);
  const [errorOn, setErrorOn] = useState(false);

  const initFn = debounce(() => {
    const shareId = window.location.pathname.split('/')[1];
    api__getArtistData(shareId).then((res) => {
      if (res.code === 'APP_20000') {
        setArtistData(res.dataset)
      } else {
        setErrorOn(true);
      }
    }).catch((err) => {
      setErrorOn(true);
      console.error(err);
    });
    api__getCalendarData(shareId).then((res) => {
      if (res.code === 'APP_20000') {
        const set = res.dataset;
        const dateList: any[] = [];
        for (let i = 0; i < set.length; i++) {
          const months = moment(set[i].scheduleDt, 'YYYYMMDD').format('YYYYMM');
          dateList.push(months);
        }
        setMonthList(Array.from(new Set(dateList)));
        setCalendarData(set);
      }
    }).catch(console.error);
  }, 200)
  useEffect(() => {
    initFn();
    // eslint-disable-next-line
  }, [])
  const isMobile: any = (device: 'Android' | 'iOS' | 'web' = 'web') => {
    if (device === 'Android') {
      return navigator.userAgent.match(/Android/i) !== null;
    } else if (device === 'iOS') {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
    } else {
      return isMobile('Android') || isMobile('iOS');
    }
  };
  const moveAppStore = () => {
    if (isMobile('Android')) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.pond.goodduck';
    } else if (isMobile('iOS')) {
      var visitedAt = new Date().getTime();
      setTimeout(function () {
        if (new Date().getTime() - visitedAt < 2000) {
          window.location.href = 'https://apps.apple.com/kr/app/id1574162509';
        }
      }, 500);
    } else window.open('https://landing-goodduck.oopy.io/2467c6f0-a0a1-4493-b9c7-1d7f67ba9115');
  };
  if (artistData === null || calendarData === null) return errorOn ? <Error /> : <BasicLoading />;
  return (
    <>
      <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto', minWidth: '375px' }}>
        <header>
          <img className='cover-img' src={artistData.coverImgUrl || basic1} alt="cover-img" />
          <h2 className="title">
            {artistData.nameKo}
          </h2>
        </header>
        <main>
          {monthList.length > 0 ? monthList.map((item: any, index: number) => (
            <ShareMonthList key={`shareMonthList_${index}`} scheduleDt={item} list={calendarData} setModal={setModal} />
          )) : (
            <div className='dc-box' style={{ height: '185px', marginTop: '46px', flexDirection: 'column' }}>
              <img src={calEmpty} style={{ width: '67px' }} alt="calendar empty" />
              <p className='empty'>등록된 일정이 없어요 :)</p>
            </div>
          )}
          <p className='body1' style={{ marginTop: '30px', marginBottom: '24px' }}>아티스트와 더 가깝게 소통하고 싶다면?</p>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '36px' }}>
            <button className='download' onClick={moveAppStore}>
              <p className='down-typo'>
                굿덕 다운로드 하기
              </p>
            </button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '54px' }}>
            <img src={logo} style={{ width: '150px' }} alt="logo" />
          </div>
        </main>
      </div>
      {modal && (
        <div className='full-size-fixed-box'>
          <div>
            <div className='modal-box'>
              <div className="modal-header">
                <p className="modal-title">
                  {artistData.nameKo}님의 일정을 상세하게 보고싶다면?
                </p>
                <p className="modal-content">
                  굿덕 앱을 다운받으세요!
                </p>
              </div>
              <div className="modal-footer">
                <button className='modal-cancel' onClick={() => { setModal(false) }}>
                  취소
                </button>
                <button onClick={() => {
                  setModal(false);
                  moveAppStore();
                }}>다운받기</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const CalSchList = ({ cal, setModal }: any) => {
  const today = moment(new Date()).format('YYYYMMDD');
  const isToday = today === cal.scheduleDt;

  return (
    <>
      <div className='dc-box' style={{ padding: '10px 16px' }} onClick={() => { setModal(true) }}>
        <div style={{ marginRight: '12px' }}>
          <p className={isToday ? 'cal-m now' : 'cal-m'}>
            {moment(cal.scheduleDt, 'YYYYMMDD').format('M월')}
          </p>
          <p className={isToday ? 'now cal-y' : 'cal-y'}>
            {moment(cal.scheduleDt, 'YYYYMMDD').format('DD')}
          </p>
        </div>
        <div className='sbc-box'>
          <p className='p1'>
            <img
              src={tagType.find((c) => c.code === cal.tag)?.img}
              style={{ width: '18px', transform: 'translateY(2px)' }}
              alt=""
            />
            &nbsp;{cal.title}
          </p>
          <div>
            {isToday && <p className='today'>Today</p>}
            <div className="fe-box">
              <Icon icon={"iconoir:eye-empty"} width={18} height={18} color='rgba(0,0,0,0.6)' />
              <p className='caption' style={{ marginLeft: '2px' }}>{cal.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ width: 'calc(100% - 32px)', margin: '0 auto', border: "1px solid #DEDEDE" }} />
    </>
  );
};

const ShareMonthList = ({ scheduleDt, list, setModal }: any) => {
  const month = moment(scheduleDt, 'YYYYMM').format('M월');
  const years = moment(scheduleDt, 'YYYYMM').format('YYYY');
  const filteredList = list.filter((cal: any) => cal.scheduleDt.includes(scheduleDt));
  return (
    <>
      <div className='sbc-box' style={{ marginBottom: '13px' }}>
        <div className='dc-box' style={{ padding: '12px', width: 'fit-content', marginRight: '8px' }}>
          <h3 className="month">{month}</h3>
          <h3 className="year">{years}</h3>
        </div>
        <div className="line" />
      </div>
      <div style={{ marginTop: '13px', marginBottom: '18px' }}>
        {filteredList && filteredList.length > 0 && (
          filteredList.map((cal: any, i: number) => (
            <CalSchList key={`calendar_${i}`} cal={cal} setModal={setModal} />
          ))
        )}
      </div>
    </>
  );
};

export default App;
